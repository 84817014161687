import { ReactComponent as ArrowRight } from "../../images/icons/arrow-right.svg";
import { ReactComponent as SuccessImage } from "../../images/home/success.svg";
import { ReactComponent as AwardsImage } from "../../images/home/awards.svg";
import { ReactComponent as TrainingImage } from "../../images/home/training.svg";
import Button from "../UI/Button";

type GradientColorType = "blue" | "purple" | "red";

interface ICardProps {
  title: string;
  decription: string;
  href?: string;
  image: string;
  color: GradientColorType;
  className?: string,
}

const gradientColors = {
  blue: "linear-gradient(90deg, #52BBC1 0%, #3F92C1 100%)",
  purple: "linear-gradient(90deg, #B662EA 0%, #7F32CC 100%)",
  red: "linear-gradient(90deg, #DE4F41 0%, #E01890 100%)",
};

const Card: React.FC<ICardProps> = ({
  title,
  decription,
  href,
  image,
  color,
  className
}) => {
  return (
    <div
      className={`${className} w-full p-7 rounded-[30px] flex items-center gap-6`}
      style={{ background: gradientColors[color] }}
    >
      <div className="min-w-[4rem] max-w-[4rem]">
        {image && <img src={image} alt={title + ' icon'} width="64" height="64" loading="lazy" />}
      </div>
      <div className="space-y-4 text-white">
        <h3 className="text-xl font-semibold">{title}</h3>
        <p className="text-base">{decription}</p>
      </div>
    </div>
  );
};

export default Card;
