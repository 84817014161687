import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {Helmet} from "react-helmet";
import { graphql, Link } from "gatsby";
import TextAndImage from "../TextAndImage";
import ListOfCards from "../ListOfCards";
import Card from "../HomePage/Card";
import useEmblaCarousel from 'embla-carousel-react'
import { ISiteMetaData } from "../types";
 import { useState, useEffect, useCallback } from 'react'

const Hero: React.FC = () => {

  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    skipSnaps: false
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
   <TextAndImage 
          backgroundColor="#302536"
          text={
            <>
              <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text mb-6">Blending Cloud Data Engineering With Data Science</h2>
              <p>We are a team of expert data engineers, data scientists and software engineers who work with our clients to deploy advanced analytics, machine learning models and real-time applications infused with AI.</p>
              <p className="mt-4">We work with regulated enterprise businesses, helping to modernise their approach to data whilst building the tools, platforms and applications that support their future ambitions.</p>
              <Link to="/about" className="size-small type-outline color-white max-w-max mt-7">About Us</Link>
            </>
          }
          image={
            <>
              <div className="flex-col gap-4 sm:flex hidden" >
                <Card
                  color="blue"
                  title="Data Engineering"
                  image="/assets/icons/design-icon.svg"
                  decription="Extract, transform and load your business data from source systems and databases into centralised data lakes and warehouses."
                />
                <Card
                  color="purple"
                  title="Data Science"
                  image="/assets/icons/build-icon.svg"
                  decription="Analyse your data for insights and build machine learning capabilities that provide predictive real-time analytics."
                />
                <Card
                  color="red"
                  title="Software Engineering"
                  image="/assets/icons/run-icon.svg"
                  decription="Develop the apps, dashboards, reports and user experiences that enhance the customer experience and drive employees next best action."
                />
              </div>

              <div className="relative sm:hidden" ref={viewportRef} >
                <div className="flex">
                  <Card
                    color="blue"
                    className="min-w-[90vw] max-w-[90vw] mr-[2vw]"
                    title="Data Engineering"
                    image="/assets/icons/design-icon.svg"
                    decription="Extract, transform and load your business data from source systems and databases into centralised data lakes and warehouses."
                  />
                  <Card
                    color="purple"
                    className="min-w-[90vw] max-w-[90vw] mr-[2vw]"
                    title="Data Science"
                    image="/assets/icons/build-icon.svg"
                    decription="Analyse your data for insights and build machine learning capabilities that provide predictive real-time analytics."
                  />
                  <Card
                    color="red"
                    className="min-w-[90vw] max-w-[90vw] mr-[2vw]"
                    title="Software Engineering"
                    image="/assets/icons/run-icon.svg"
                    decription="Develop the apps, dashboards, reports and user experiences that enhance the customer experience and drive employees next best action."
                    />
                </div>
              </div>
            </>
          }
        />
</>
  );
};

export default Hero;
