import { Link } from "gatsby"
import { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'


export default function ListOfCards({ items }: {
    items: Array<any>,
}) {

    // All of this enables the carousel and its navigation dots
    // I just copy pasted it from the docs: https://www.embla-carousel.com/api/
    const [viewportRef, embla] = useEmblaCarousel({
        loop: true,
        skipSnaps: false
    });
    const [selectedIndex, setSelectedIndex] = useState(0);
  
    const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [
      embla
    ]);
  
    const onSelect = useCallback(() => {
      if (!embla) return;
      setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);
  
    useEffect(() => {
      if (!embla) return;
      onSelect();
      embla.on("select", onSelect);
    }, [embla, onSelect]);
    
    return (
        <div className="overflow-hidden pt-[1rem]">
            <section className="max-w-[1140px] pb-[6rem] mx-auto flex flex-wrap gap-4 md:gap-x-4 gap-x-[3%] w-[90%] justify-center">

                {items.map((loop, i) => {

                    const colorFrom = (!i && i !== 0) ? '#de4d43' : 
                        (i + 1) % 7 === 0 ? '#de4d43' :
                        (i + 1) % 6 === 0 ? '#5296bf' :
                        (i + 1) % 5 === 0 ? '#f4a053' :
                        (i + 1) % 4 === 0 ? '#d57acd' :
                        (i + 1) % 3 === 0 ? '#51b8c1' :
                        (i + 1) % 2 === 0 ? '#59bb8b' :
                        (i + 1) % 1 === 0 ? '#de4d43' : 
                        '#7bd89b';

                    const colorTo = (!i && i !== 0) ? '#4bb990' : 
                        (i + 1) % 7 === 0 ? '#4bb990' :
                        (i + 1) % 6 === 0 ? '#3d81e6' :
                        (i + 1) % 5 === 0 ? '#e57f20' :
                        (i + 1) % 4 === 0 ? '#c25bd1' :
                        (i + 1) % 3 === 0 ? '#4094c1' :
                        (i + 1) % 2 === 0 ? '#51c56c' :
                        (i + 1) % 1 === 0 ? '#e01b8c' : 
                        '#4bb990';

                    return (

                        <div key={i} className="
                                group p-[1.25em] bg-[#302536] rounded-[0.6em] relative
                                max-w-[min(13.3rem,48%)] min-w-[min(13.3rem,48%)] sm:block hidden
                                hover:translate-y-[-1rem] transition-all z-[2]
                            "
                            style={{
                                background: `linear-gradient(to right, ${colorFrom}, ${colorTo})`,
                            }}
                        >
                            <img
                                src={loop.icon}
                                className="p-[0.55em] w-10 bg-[#fff] rounded-full"
                                height="24"
                                width="24"
                                alt={loop.line2 + ' icon'}
                                loading="lazy"
                            />

                            <Link
                                to={loop.linksTo}
                                target={loop.linksTo.startsWith('http') ? '_blank' : ''}
                                rel={loop.linksTo.startsWith('http') ? 'nonopener' : ''}
                                className="mt-5 block after:absolute after:top-0 after:left-0 after:w-full after:h-full pr-5"
                            >
                                <span className="block">{loop?.line1}</span>
                                <span className="block font-bold">{loop?.line2}</span>
                            </Link>

                            <svg className="absolute bottom-6 right-2" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.2002 17L17.2002 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.2002 7H17.2002V17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <svg className="absolute bottom-[-4.5rem] z-[-1] left-[50%] translate-x-[-50%]" width="278" height="227" viewBox="0 0 278 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.25" filter="url(#filter0_f_4732_12652)">
                                    <ellipse cx="139" cy="113.5" rx="95" ry="69.5" fill={`url(#paint0_linear_4732_12652-${i})`}/>
                                </g>
                                <defs>
                                    <filter id="filter0_f_4732_12652" x="0" y="0" width="278" height="227" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feGaussianBlur stdDeviation="22" result="effect1_foregroundBlur_4732_12652"/>
                                    </filter>
                                    <linearGradient id={`paint0_linear_4732_12652-${i}`} x1="44" y1="113.5" x2="234" y2="113.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color={colorFrom}/>
                                    <stop offset="1" stop-color={colorTo}/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    )
                })}

                <div className="relative sm:hidden" ref={viewportRef} >
                    <div className="flex">
                        {items.map((loop, i) => {

                            const colorFrom = (!i && i !== 0) ? '#de4d43' : 
                                (i + 1) % 7 === 0 ? '#de4d43' :
                                (i + 1) % 6 === 0 ? '#5296bf' :
                                (i + 1) % 5 === 0 ? '#f4a053' :
                                (i + 1) % 4 === 0 ? '#d57acd' :
                                (i + 1) % 3 === 0 ? '#51b8c1' :
                                (i + 1) % 2 === 0 ? '#59bb8b' :
                                (i + 1) % 1 === 0 ? '#de4d43' : 
                                '#7bd89b';

                            const colorTo = (!i && i !== 0) ? '#4bb990' : 
                                (i + 1) % 7 === 0 ? '#4bb990' :
                                (i + 1) % 6 === 0 ? '#3d81e6' :
                                (i + 1) % 5 === 0 ? '#e57f20' :
                                (i + 1) % 4 === 0 ? '#c25bd1' :
                                (i + 1) % 3 === 0 ? '#4094c1' :
                                (i + 1) % 2 === 0 ? '#51c56c' :
                                (i + 1) % 1 === 0 ? '#e01b8c' : 
                                '#4bb990';

                            return (

                                <div key={i} className="
                                        group p-[1.25em] rounded-[0.6em] relative
                                        max-w-[84vw] min-w-[84vw] mr-[2vw] z-[2]
                                    "
                                    style={{
                                        background: `linear-gradient(to right, ${colorFrom}, ${colorTo})`,
                                    }}
                                >
                                    <img
                                        src={loop.icon}
                                        className="p-[0.55em] w-10 bg-[#fff] rounded-full"
                                        height="24"
                                        width="24"
                                        alt={loop.line2 + ' icon'}
                                        loading="lazy"
                                    />

                                    <Link
                                        to={loop.linksTo}
                                        target={loop.linksTo.startsWith('http') ? '_blank' : ''}
                                        rel={loop.linksTo.startsWith('http') ? 'nonopener' : ''}
                                        className="mt-5 block after:absolute after:top-0 after:left-0 after:w-full after:h-full"
                                    >
                                        <span className="block">{loop?.line1}</span>
                                        <span className="block font-bold">{loop?.line2}</span>
                                    </Link>

                                    <svg className="absolute bottom-6 right-2" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.2002 17L17.2002 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.2002 7H17.2002V17" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> 
                                </div>
                            )
                        })}
                    </div>
                </div>

            </section>
        </div>
    );
};