export default function TextAndImage({ text, image, backgroundColor }: {
    text: any,
    image: any,
    backgroundColor?: string,
}) {
    
  return (
    <section style={{backgroundColor: backgroundColor ? backgroundColor : null}} className="overflow-hidden">
      <div
        className="max-w-[1140px] py-[3rem] md:py-[6rem] mx-auto flex md:flex-row flex-col items-center gap-12 w-[90%]"
      >

          <div className="flex-1">
              {text}
          </div>

          <div className="flex-1">
              {image}
          </div>

      </div>
    </section>
  );
};