import React from "react";
import { graphql, Link } from "gatsby";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/HomePage/Hero";
import InnovateAndOutperformFeature from "../components/HomePage/InnovateAndOutperformFeature";
import BlendCloudDataEngineeringFeature from "../components/HomePage/BlendCloudDataEngineeringFeature";
import EnhanceEmployeeExperienceFeature from "../components/HomePage/EnhanceEmployeeExperienceFeature";


import { ISiteMetaData } from "../types";
import { StaticImage } from "gatsby-plugin-image";
import { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

interface IHomePage {
  data: {
    site: {
      siteMetadata: ISiteMetaData;
    }
  };
}

const HomePage: React.FC<IHomePage> = ({ data }) => {

  // All of this enables the carousel and its navigation dots
  // I just copy pasted it from the docs: https://www.embla-carousel.com/api/
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    skipSnaps: false
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [
    embla
  ]);

  var headline = "Title Goes Here"

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

 // useEffect(() => {
  //  window.posthog.init('phc_FQVzubXz8txO5hAdLf0HwgvVgQdldaynBmUR2YU1uc3', { api_host: 'https://eu.posthog.com' })
  //  const waitForPosthogToLoad = function () {
      // @ts-expect-error
  //    if (!window.posthog) {
    //    setTimeout(waitForPosthogToLoad, 25)
   //   } else {
        // @ts-expect-error
     //   console.log(`Is feature flag enabled: ${window.posthog.isFeatureEnabled('clickhouse-mode')}`)
     //   headline = "Title Goes Here"
     // }
   // }

   // waitForPosthogToLoad();
  //}, [])

  return (
    <>
      <SEO
        url={data.site.siteMetadata.siteUrl}
        title={data.site.siteMetadata.title}
        desc={data.site.siteMetadata.description}
        ogImage={`${data.site.siteMetadata.siteUrl}/assets/img/ensemble_background.png`}
      />
      <DefaultLayout>

        <Hero />
        <InnovateAndOutperformFeature/>
        <BlendCloudDataEngineeringFeature/>
        <EnhanceEmployeeExperienceFeature/>

       
      </DefaultLayout >
    </>
  );
};

export const query = graphql`
  query lessons {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;

export default HomePage;
