export default function ListOfCards({ text, image1, image2, cta }: {
    text?: any,
    image1?: any,
    image2?: any,
    cta?: any,
}) {
    
  return (
    <section className="max-w-[1140px] py-[6rem] mx-auto flex flex-wrap gap-4 md:gap-x-4 gap-x-[3%] w-[90%] justify-center">
            
        <div className="max-w-[800px] mb-4 text-center mx-auto">
            {text}
        </div>

        <div className="flex my-2 md:flex-row flex-col gap-5 justify-center">
            <div className="flex-1">
                {image1}
            </div>
            {image2 &&
                <div className="flex-1">
                    {image2}
                </div>
            }
        </div>

        {cta}

    </section>
  );
};