import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {Helmet} from "react-helmet";
import TextAndImage from "../TextAndImage";
import ListOfCards from "../ListOfCards";

const Hero: React.FC = () => {
  return (
    <>
    <TextAndImage
    text={
      <>
        <h2 className="text-[28px] lg:text-[30px] font-bold gradient-blue-text mb-6">Accelerate Your Adoption Of Machine Learning & Generative AI</h2>
        <p>We are an engineering led consultancy who help businesses extract value from their data - using it to grow revenue and combat threats such as financial crime, cyber crime and regulatory risk.</p>
        <p className="mt-4">By deploying powerful cloud hosted analytics platforms and AI driven applications, we help our clients gain a real time view of their world, solve their most complex problems and gain an edge in competitive markets.</p>
      </> 
    }
    image={
      <StaticImage
        src="../../images/home/clickhouse-5.png"
        alt="Screenshots of revenue charts"
      />
    }
  />
  <ListOfCards
  items={[
    { icon: '/assets/icons/coin.svg', line1: 'Solutions', line2: 'Real Time Analytics', linksTo: '/solutions/usecase/operational-analytics' },
    { icon: '/assets/icons/alert.svg', line1: 'Solutions', line2: 'Real Time Data Warehouse', linksTo: '/usecase/realtime-dwh' },
    { icon: '/assets/icons/bag.svg', line1: 'Solutions', line2: 'Business Intelligence', linksTo: '/solutions/usecase/business-intelligence' },
    { icon: '/assets/icons/location.svg', line1: 'Solutions', line2: 'Predictive Analytics', linksTo: '/solutions/usecase/predictive-analytics' },
    { icon: '/assets/icons/map.svg', line1: 'Solutions', line2: 'User Facing Analytics', linksTo: '/solutions/usecase/user-facing-analytics' }
  ]}
/>
</>
  );
};

export default Hero;
