import { StaticImage } from "gatsby-plugin-image";
import {Helmet} from "react-helmet";

const Hero: React.FC = () => {
  return (
    <div
      className="w-full mx-auto flex items-center pt-[96.54px] lg:pt-[136px] relative px-6 bg-gradient-to-r from-[#40b7de] to-[#1b78e0] overflow-hidden rounded-b-[2em] lg:rounded-b-[5em]"
    >
      <div className="flex gap-6 lg:gap-[64px] flex-col justify-between max-w-[1140px] mx-auto">

        <img
          src="/assets/img/home-background-waves.svg"
          className="w-full absolute top-0 left-0 h-full z-[0]"
          alt="" aria-hidden="true"
          width="1000" height="600"
          loading="eager"
        />

        <div className="w-full max-w-[900px] mx-auto">
          <h1 className="text-[34px] lg:text-[40px] font-bold text-white text-center">
            <span>We Build AI Enabled Businesses</span>
          </h1>
          <p className="text-base text-white text-center mt-4">We help businesses deploy advanced analytics and intelligent AI enabled applications that grow revenue, enhance customer experience and combat threats.
          {/*<p className="text-base text-white text-center mt-4">We help enterprise organisations use real-time analytics, data science and applied AI to grow revenue, enhance their customer experience and combat threats.*/}
          {/*<br/>To power our solutions, we use ClickHouse, the fastest open-source database in the market.*/}
          {/*we help enterprise organisations use advanced analytics and applied AI to grow their business, fight financial crime and improve regulatory compliance.*/}
          </p>
        </div>

        <StaticImage
          src="../../images/home/hero-image.png"
          alt="Screenshot of a real time analytics dashboard"
          className="w-full max-w-[980px] overflow-hidden"
          loading="eager"
          style={{ borderRadius: '16px 16px 0 0' }}
        />

      </div>
    </div>
  );
};

export default Hero;
