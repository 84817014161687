import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {Helmet} from "react-helmet";
import TextAndImage from "../TextAndImage";
import ListOfCards from "../ListOfCards";
import TextAndTwoImages from "../TextAndTwoImages";
import { graphql, Link } from "gatsby";

const Hero: React.FC = () => {
  return (
    <>
     <TextAndTwoImages
          text={
            <>
              <h2 className="text-[28px] max-w-[750px] mx-auto lg:text-[30px] font-bold gradient-blue-text mb-6">Augment Your Decision Making With Intelligent AI Enabled Applications</h2>
              <p>Work with us to deploy greenfield or modernised AI driven applications that drive your employees next best action and enhance the customer experience.</p>
            </>
          }
          image1={
            < StaticImage
              src="../../images/home/ensemblejs-1.png"
              alt="EnsembleJS Image"
              style={{ borderRadius: '16px 16px 0 0' }}
            />
          }
          image2={
            < StaticImage
              src="../../images/home/ensemblejs-2.png"
              alt="EnsembleJS Image"
              style={{ borderRadius: '16px 16px 0 0' }}
            />
          }
          cta={
            < Link className="size-small type-outline color-white max-w-max mt-7" to="/about">Tell Me More</Link >
          }
        />

</>
  );
};

export default Hero;
